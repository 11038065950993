import { ref, reactive, computed, onMounted } from 'vue';
import * as API from '@/API/checking/spotChecking';
import {
  SearchParams,
  DealerTableData,
  AFCandLCTableData
} from '@/views/Checking/spotChecking/spotCheckingVonder/checked/types';
import moment from 'moment';
import useThousands from '@/utils/payment/useThousands';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from 'ant-design-vue';
import downloadFile from '@/utils/payment/downloadFile';

type Key = ColumnProps['key'];

const useChecked = () => {
  const { commit } = useStore();
  const router = useRouter();

  const startDate = moment().month(moment().month()).startOf('month').format('YYYY-MM-DD');
  const endDate = moment().month(moment().month()).endOf('month').format('YYYY-MM-DD');

  const searchParams = reactive<SearchParams>({
    entity: undefined,
    bu: undefined,
    submissionPeriod: [startDate, endDate],
    priority: undefined
  });

  const dealerColData = computed(() => {
    return [
      { title: 'Package Code', dataIndex: 'packageCode', slots: { customRender: 'packageCode' }, width: 250 },
      { title: 'Time Line From', dataIndex: 'timeLineFrom', slots: { customRender: 'timeLineFrom' }, width: 150 },
      { title: 'To', dataIndex: 'timeLineTo', slots: { customRender: 'timeLineTo' }, width: 100 },
      { title: 'Program No.', dataIndex: 'programNo', customRender: useThousands(), align: 'right', width: 150 },
      { title: 'Confirmed No.', dataIndex: 'confirmedNo', customRender: useThousands(), align: 'right', width: 150 },
      { title: 'Assignee', dataIndex: 'assignee', width: 200 },
      { title: 'Total Amount', dataIndex: 'totalAmount', align: 'right', width: 120 },
      { title: 'Priority', dataIndex: 'priority', width: 150 },
      { title: 'Status', dataIndex: 'status', width: 200 },
      { title: 'Last Update Time', dataIndex: 'lastUpdateTime', slots: { customRender: 'lastUpdateTime' }, width: 150 }
    ];
  });
  const afcAndLcColData = computed(() => {
    return [
      { title: 'Payout Round', dataIndex: 'payoutRound', width: 180 },
      { title: 'Time Line From', dataIndex: 'timeLineFrom', slots: { customRender: 'timeLineFrom' }, width: 150 },
      { title: 'To', dataIndex: 'timeLineTo', slots: { customRender: 'timeLineTo' }, width: 100 },
      { title: 'Program No.', dataIndex: 'programNo', customRender: useThousands(), align: 'right', width: 120 },
      { title: 'Confirmed No.', dataIndex: 'confirmedNo', customRender: useThousands(), align: 'right', width: 120 },
      { title: 'Assignee', dataIndex: 'assignee', slots: { customRender: 'assignee' }, width: 130 },
      { title: 'Total Amount', dataIndex: 'totalAmount', align: 'right', width: 120 },
      { title: 'Priority', dataIndex: 'priority', width: 130 },
      { title: 'Status', dataIndex: 'status', width: 200 },
      { title: 'Last Update Time', dataIndex: 'lastUpdateTime', slots: { customRender: 'lastUpdateTime' }, width: 150 },
      { title: 'Operation', dataIndex: 'operation', slots: { customRender: 'operation' }, align: 'center', width: 250 }
    ];
  });
  const dealerTblData = ref<DealerTableData[]>();
  const afcAndLcTblData = ref<AFCandLCTableData[]>();
  const getTableData = (type: string) => {
    const params = {
      entityList: searchParams.entity,
      buList: searchParams.bu,
      submissionPeriodStart: searchParams.submissionPeriod[0] + ' 00:00:00',
      submissionPeriodEnd: searchParams.submissionPeriod[1] + ' 23:59:59',
      priority: searchParams.priority ? searchParams.priority : ''
    };
    if (type === 'dealer') {
      API.getCheckedDealerList(params).then((data: any) => {
        dealerTblData.value = data;
      });
    } else {
      // 金融bu、entity不参与筛选
      params.buList = undefined;
      params.entityList = undefined;
      API.getCheckedFinanceList(params).then((data: any) => {
        afcAndLcTblData.value = data;
      });
    }
  };
  const rowSelectionDealer = reactive<{
    selectedRowKeys: Key[];
    selectedRowData: DealerTableData[];
    onChange: Function;
  }>({
    onChange: (selectedRowKeys: Key[], selectedRows: DealerTableData[]) => {
      rowSelectionDealer.selectedRowKeys = selectedRowKeys;
      rowSelectionDealer.selectedRowData = selectedRows;
    },
    selectedRowKeys: [],
    selectedRowData: []
  });

  const rowSelectionAFC = reactive<{
    selectedRowKeys: Key[];
    selectedRowData: AFCandLCTableData[];
    onChange: Function;
  }>({
    onChange: (selectedRowKeys: Key[], selectedRows: AFCandLCTableData[]) => {
      rowSelectionAFC.selectedRowKeys = selectedRowKeys;
      rowSelectionAFC.selectedRowData = selectedRows;
    },
    selectedRowKeys: [],
    selectedRowData: []
  });

  const doSearch = () => {
    dealerTblData.value = [];
    afcAndLcTblData.value = [];
    getTableData('dealer');
    getTableData('afcAndLc');
  };
  const doReset = () => {
    Object.assign(searchParams, {
      entity: undefined,
      bu: undefined,
      submissionPeriod: [startDate, endDate],
      priority: undefined
    });
    doSearch();
  };

  //#region
  const uploadVisible = ref(false);
  const payoutRoundInfo = ref<AFCandLCTableData>();
  const openUpload = (record?: AFCandLCTableData) => {
    uploadVisible.value = true;
    payoutRoundInfo.value = record;
    // record.payoutRound
  };
  const closeUpload = (param: { isUploadVisible: boolean }) => {
    uploadVisible.value = param.isUploadVisible;
  };
  //#endregion

  const handleGoToPackageCodeOverview = (record: any) => {
    commit('spotChecking/updateSpotCheckConfirmPackageDealer', {
      ...record,
      type: 'checkedOverview'
    });
    router.push('/checkedProgramDealer');
  };

  const isSelected = (flag: string, showMessage = true) => {
    const initFlag = ref<boolean>(true);
    switch (flag) {
      case 'top':
        if (rowSelectionDealer.selectedRowData.length === 0) {
          showMessage && message.warning('Please select files');
          initFlag.value = false;
        }
        break;
      case 'bottom':
        if (rowSelectionAFC.selectedRowData.length === 0) {
          showMessage && message.warning('Please select files');
          initFlag.value = false;
        }
        break;
      default:
    }
    return initFlag.value;
  };

  const batchDownloadDealer = () => {
    if (!isSelected('top')) {
      return;
    }
    downloadDealerFile();
  };
  const downloadDealerFile = () => {
    const packageCodes = rowSelectionDealer.selectedRowData.map((item: any) => item.id);
    const params = {
      url: '/claimapi/vendor/checked/dealer/batch/download',
      method: 'post',
      params: packageCodes
    };
    downloadFile(params);
  };

  const batchDownloadAFC = () => {
    if (!isSelected('bottom')) {
      return;
    }
    downloadAFCFile();
  };
  const downloadAFCFile = () => {
    const payoutRoundIds = rowSelectionAFC.selectedRowData.map((item: any) => item.id);
    const params = {
      url: '/claimapi/vendor/checked/finance/batch/download',
      method: 'post',
      params: payoutRoundIds
    };
    downloadFile(params);
  };

  onMounted(() => {
    doSearch();
  });
  return {
    searchParams,
    dealerColData,
    afcAndLcColData,
    dealerTblData,
    afcAndLcTblData,
    doSearch,
    doReset,
    uploadVisible,
    payoutRoundInfo,
    openUpload,
    closeUpload,
    handleGoToPackageCodeOverview,
    rowSelectionDealer,
    rowSelectionAFC,
    isSelected,
    batchDownloadDealer,
    batchDownloadAFC
  };
};
export default useChecked;
